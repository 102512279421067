@import '../../styles/variables';

.app {
  height: 100vh;
  width: 100%;
  background-color: $gray-200;
  overflow: hidden;
}

.app-container {
  margin: 4rem auto 0;
  max-width: 26rem;
}

.logo {
  text-align: center;
  margin-bottom: 2rem;
}

.logo img {
  width: 12rem;
}

.form-container {
  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  padding: 2rem;
}

.form-container input {
  background-color: #f7fafc;
}
