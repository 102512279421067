$red-100: #fff5f5;
$red-200: #fed7d7;
$red-300: #feb2b2;
$red-400: #fc8181;
$red-500: #f56565;
$red-600: #e53e3e;
$red-700: #c53030;
$red-800: #9b2c2c;
$red-900: #742a2a;

$blue-100: #ebf8ff;
$blue-200: #bee3f8;
$blue-300: #90cdf4;
$blue-400: #63b3ed;
$blue-500: #4299e1;
$blue-600: #3182ce;
$blue-700: #2b6cb0;
$blue-800: #2c5282;
$blue-900: #2a4365;

$gray-100: #f7fafc;
$gray-200: #edf2f7;
$gray-300: #e2e8f0;
$gray-400: #cbd5e0;
$gray-500: #a0aec0;
$gray-600: #718096;
$gray-700: #4a5568;
$gray-800: #2d3748;
$gray-900: #1a202c;

$indigo-100: #ebf4ff;
$indigo-200: #c3dafe;
$indigo-300: #a3bffa;
$indigo-400: #7f9cf5;
$indigo-500: #667eea;
$indigo-600: #5a67d8;
$indigo-700: #4c51bf;
$indigo-800: #434190;
$indigo-900: #3c366b;
