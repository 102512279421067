@import './styles/variables';

$blue: $blue-600;
$body-bg: $gray-200;
$body-color: $gray-800;
$enable-transitions: false;
$font-size-base: 0.875rem;
$grid-gutter-width: 2rem;
$hr-border-color: #cbd5e0;
$red: $red-500;

@import '~bootstrap/scss/bootstrap.scss';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100vh;
}
